.Grid {
  height: 100%;
  width: 100%;
}

.react-grid-item.cssTransforms {
  transition-property: none;
}

.dragging .react-grid-item.cssTransforms {
  transition-property: transform;
}

.FixedGridScrollButtonsOnTheSides::-webkit-scrollbar {
  display: none;
}

.FixedGridScrollButtonsOnTheSides {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
