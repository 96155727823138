.dialogBar {
  color: #fff;
  background-color: #3f51b5;
  display: flex;
  justify-content: space-between;
}

.isDark .dialogBar {
  background-color: #78909c;
}

.dialogBar > h2 {
  width: 100%;
}

button.closeButton {
  padding-right: 0;
}

.close {
  color: #fff;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.card {
  width: 100%;
  height: 100%;
}

.cardContent:last-child {
  padding-bottom: 0;
}
