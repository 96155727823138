.CommunicatorToolbar {
  position: relative;
  display: flex;
  justify-content: space-around;
  height: 40px;
  color: #fff;
  background-color: #4b4b4b;
  white-space: nowrap;
  align-items: center;
}

.CommunicatorToolbar button {
  color: #fff;
}
  .CommunicatorToolbar button icon {
    margin-left: 0;
  }

.CommunicatorToolbar__menu {
  text-transform: capitalize;
}

.CommunicatorToolbar__group {
  display: flex;
  flex: 1 1;
  align-items: center;
}

.CommunicatorToolbar .Communicator__title {
  display: flex;
  order: 2;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  height: 100%;
  margin: 0;
  font-size: 1.14rem;
  font-weight: 400;
  text-transform: uppercase;
}

.Communicator__title.logged-in {
  cursor: pointer;
}

.CommunicatorToolbar__group--start {
  justify-content: flex-start;
}
.CommunicatorToolbar__group--start--button {
  justify-content: flex-start;
  margin-right: 5px;
}

.CommunicatorToolbar__group--end {
  order: 2;
  justify-content: flex-end;
}

.CommunicatorToolbar__menuitem {
  max-width: 360px;
}
