.ChangePassword {
  height: 100%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #eceff1;
  background: linear-gradient(to right, rgb(121, 22, 254), rgb(172, 47, 138));
}

.ChangePassword_home {
  margin-top: 1em;
  color: inherit;
}
.ChangePassword__form > div {
  width: 100%;
}

.ChangePassword__form > div:not(:first-child) {
  margin-top: 0.75em;
}

.ChangePassword__status {
  padding: 1rem 0;
}

.ChangePassword__status--success {
  color: green;
}

.ChangePassword__status--error {
  color: #f44336;
}
