.root {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  /* scroll-snap-type: y mandatory; */
}

.grid {
  flex-shrink: 0;
  /* scroll-snap-align: start; */
}
