.CboardLogo {
  padding: 2vh;
  margin: 0 auto;
  display: block;
  max-height: 50vh;
  height: 85%;
  max-width: 100%;
  object-fit: contain;
}

.transition-enter {
  opacity: 0;
  transform: scale(0.9);
}
.transition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 5000ms, transform 5000ms;
}
