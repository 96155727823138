.Activate {
  height: 100%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #eceff1;
  background-color: linear-gradient(
    to right,
    rgb(45, 22, 254),
    rgb(141, 92, 255)
  );
}

.Activate_home {
  margin-top: 1em;
  color: inherit;
}
