.SymbolOutput {
  height: 100%;
  width: 100%;
  display: flex;
  background: #fff;
  color: rgba(0, 0, 0, 0.54);
}

.is-dark .SymbolOutput {
  background: #7b7b7b;
  color: #000;
}

.SymbolOutput__value {
  height: calc(100% - 10px);
  min-width: 100px;
  padding: 0 5px;
  position: relative;
}

.LiveSymbolOutput__value {
  display: flex;
  height: 100%;
  min-width: 140px;
  padding: 3px 5px;
  align-self: flex-end;
  position: relative;
}

.SymbolOutput__symbol {
  color: #000;
}

.SymbolOutput__value__IconButton {
  position: absolute;
  --close-button-top: -10px;
  top: -10px;
  top: var(--close-button-top);
  right: -10px;
}

.LiveSymbolOutput__value > .SymbolOutput__value__IconButton {
  --close-button-top-live-difference: -5px;
  top: -5px;
  top: calc(var(--close-button-top) - var(--close-button-top-live-difference));
}

.SymbolOutput__right__btns {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  height: 100%;
  overflow: visible;
  justify-content: center;
}

@media (max-height: 696px) {
  .SymbolOutput__right__btns {
    flex-direction: row-reverse;
    align-items: center;
  }
}

.SymbolOutput__right__btns__lg {
  display: flex;
  flex-direction: row-reverse;
  width: auto;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.SymbolOutput__right__btns .MuiFormControlLabel-label {
  font-size: 11px;
}

button.Output__button__sm {
  align-self: center;
  height: 64px;
  width: 64px;
}

button.Output__button__lg {
  align-self: center;
  height: 100px;
  width: 100px;
  margin-right: 15px;
}

svg.Output__icon__sm {
  height: 32px;
  width: 32px;
}

svg.Output__icon__lg {
  height: 50px;
  width: 50px;
}

/* Prevent the big buttons on small screens */

@media (min-width: 480px) {
  label.Live__switch_lg {
    height: 95%;
    width: 100px;
    justify-content: center;
    margin-left: 0px;
    margin-right: 15px;
  }
}

@media (max-width: 480px) {
  .SymbolOutput__right__btns__lg {
    display: flex;
    flex-direction: column;
    flex-wrap: n;
    height: 100%;
    overflow: visible;
    justify-content: center;
  }

  button.Output__button__lg {
    align-self: center;
    height: 64px;
    width: 64px;
    margin-right: unset;
  }

  svg.Output__icon__lg {
    height: 32px;
    width: 32px;
  }
}
