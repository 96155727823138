.Scroll__container {
  position: relative;
  width: auto;
  height: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  overflow-x: auto;
  padding-left: 0.7em;
  padding-right: 0.7em;
}

.Scroll__container:not(:empty):focus {
  outline: 0;
  background-color: #e0e0e0;
}

.is-dark .Scroll__container:not(:empty):focus {
  background-color: #757575;
}

.Scroll__container:not(:empty) {
  cursor: pointer;
}

.Scroll__container:not(.scanner__focused) {
  outline: 0;
}
