.InputImage {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  line-height: 48px;
  cursor: pointer;
}

.InputImage__input {
  display: none;
}

.InputImage > svg {
  color: inherit;
}

.InputImage__label {
  position: relative;
  height: 100%;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 0.875rem;
  cursor: pointer;
  overflow: visible;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.InputImage__button {
  width: 100%;
}
.InputImage__buttonContainer {
  margin-top: 6px;
}
