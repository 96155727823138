.TileEditor__container {
  display: flex;
  flex-direction: column;
}

.TileEditor__row {
  width: 100%;
  flex-shrink: 0;
  margin-right: 16px;
  text-align: left;
}

[dir='rtl'] .TileEditor__row {
  margin-right: 0;
  margin-left: 16px;
}

.TileEditor__main-info {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.TileEditor__picto-fields {
  display: flex;
  flex-direction: column;
  width: 35%;
  padding: 8px;
}

@media (max-width: 500px) {
  .TileEditor__main-info {
    flex-direction: column;
    align-items: center;
  }
  .TileEditor__picto-fields {
    width: 50%;
  }
}

.TileEditor__preview {
  position: relative;
  margin-bottom: 10px;
}

.TileEditor__preview::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.TileEditor__preview .Tile {
  position: absolute;
  top: 0;
  left: 0;
}

.TileEditor__form-fields {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 16px;
}

.TileEditor__radiogroup {
  margin-top: 15px;
}

.TileEditor__colorselect {
  margin-top: 15px;
}
.TileEditor__voicerecorder {
  margin-top: 15px;
}

.TileEditor__radiogroup__formcontrollabel {
  margin-top: 5px;
}
