.Doughnut__Card {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 24px;
}

.Doughnut__Card__Title {
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: 500;
  margin-bottom: 1rem;
}
