.Forgot__form > div {
  width: 100%;
}

.Forgot__form > div:not(:first-child) {
  margin-top: 0.75em;
}

.Forgot__status {
  padding: 1rem 0;
}

.Forgot__status--success {
  color: green;
}

.Forgot__status--error {
  color: #f44336;
}
