.Login__form > div {
  width: 100%;
}

.Login__form > div:not(:first-child) {
  margin-top: 0.75em;
}

.Login__status {
  padding: 1rem 0;
}

.Login__status--success {
  color: green;
}

.Login__status--error {
  color: #f44336;
}
