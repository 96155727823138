.AuthScreen {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 1em 1em 2.5em;
  z-index: 10;
  color: #eceff1;
  background: linear-gradient(to right, rgb(121, 22, 254), rgb(172, 47, 138));
  overflow: auto;
}

.AuthScreen__container {
  height: auto;
  display: flex;
  flex-direction: column;
  max-width: 680px;
  margin: 0 auto;
}

.AuthScreen__container > button {
  width: 50px;
}

.AuthScreen__content {
  flex: 1;
  text-align: center;
  height: 100%;
}

.AuthScreen__heading {
  color: #eceff1 !important;
}

.AuthScreen__footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: calc(50% - 0.5em);
  min-width: 300px;
  margin: 0 auto;
}

.AuthScreen__footer .AuthScreen__button {
  width: 100%;
  margin: 0.8em auto 0;
}

.AuthScreen__button--skip {
  margin: 1em auto 0 auto;
}

.AuthScreen__footer .AuthScreen__button--google,
.AuthScreen__footer .AuthScreen__button--facebook {
  margin: 0.8em auto 0 !important;
  height: 36px !important;
  font-size: 1.1rem !important;
}

.AuthScreen__button--google div > span,
.AuthScreen__button--facebook div > span {
  display: inline-block;
  text-align: center;
  width: calc(100% - 26px);
}

.AuthScreen__button--google div > svg,
.AuthScreen__button--facebook div > svg {
  width: 22px;
  height: 22px;
}

.AuthScreen__button--google div:nth-child(2n),
.AuthScreen__button--facebook div:nth-child(2n) {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  height: 100%;
}
