.Navbar {
  position: relative;
  display: flex;
  justify-content: space-around;
  height: 48px;
  background: #222;
  color: #fff;
}

.Navbar__title {
  display: flex;
  order: 2;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  height: 100%;
  margin: 0;
  font-size: 1.14rem;
  font-weight: 400;
  text-transform: uppercase;
  pointer-events: none;
}

.Navbar__group .Navbar__deactivateScanner {
  color: #fff;
}

.Navbar__group {
  flex: 1;
  display: flex;
}

.Navbar__group--start {
  justify-content: flex-start;
}

.Navbar__group--end {
  order: 3;
  justify-content: flex-end;
}

.Navbar .UserIcon__Avatar {
  margin: -5px 0;
  width: 30px;
  height: 30px;
  color: #fff;
  cursor: auto;
}

.Navbar .UserIcon__Avatar img {
  width: 100%;
  height: 100%;
}

.Navbar .LoginSignUpButton,
.Navbar .LoginSignUpButton:active,
.Navbar .LoginSignUpButton:visited,
.Navbar .LoginSignUpButton:hover,
.Navbar .LoginSignUpButton:focus {
  color: #fff;
}
