.VoiceRecorder {
  display: flex;
  align-items: center;
  padding: 12px 0;
}

.VoiceRecorder__progress {
  width: 50%;
  padding: 12px;
}
