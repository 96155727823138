.Export__SelectContainer {
  display: grid;
  position: relative;
  grid-gap: 5px;
}

.Export__SelectContainer__Select {
  width: 110px;
}

.Export__ListItem {
  max-height: 100%;
}

.Export__ListItemText {
  max-width: 75%;
}

.Export__SelectContainer--spinner {
  vertical-align: middle;
}

.Export__section {
  margin-bottom: 8px;
}

.Export__List .MuiListSubheader-root {
  line-height: 16px;
  padding-top: 16px;
}
