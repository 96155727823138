.root {
  position: relative;
  flex: 1 auto;
  width: 0;
  margin: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.root:empty {
  background: rgba(128, 128, 128, 0.15);
}

.root::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 0, 0, 0.25);
  z-index: 1;
  opacity: 0;
  pointer-events: none;
}

.isActive::after {
  opacity: 1;
}
