.content {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 2em 3em;
}

.dialogText {
  padding-bottom: 1em;
}

@media (max-width: 480px), (max-height: 400px) {
  .content {
    margin: 2em 1em;
  }

  .content h3 {
    font-size: 2em;
  }
  .content h6 {
    font-size: 1.2em;
  }
}
